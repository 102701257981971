<template>
  <ion-page>
    <ion-content>
      <div class="background-primary">
        <div class="center-container">
          <img src="@/assets/v2/alloopharma-logo.svg" alt="Logo" class="logo" />

          <ion-button
              color="primary-v2"
              expand="block"
              size="large"
              fill="clear"
              class="start-button ion-margin-vertical"
              @click="goToSignUp"
          >
            Cliquez ici pour commencer
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import router from "@/router";
import {IonButton, IonPage, IonContent} from "@ionic/vue";

const goToSignUp = () => {
  router.push("/v2/inscription")
};
</script>

<style scoped>
.background-primary {
  background: var(--ion--v2-color-primary-gradiant);
  height: 100vh;
  width: 100vw;
  position: relative;
}

.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.start-button {
  background-color: white;
  border-radius: 40px;
}
</style>
